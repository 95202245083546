import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";

import styles from "./Overlay.module.scss";
import logoSvg from "../../assets/img/centrum-logo.svg";

const Overlay = () => {
  const [isOverlayVisible, setOverlayVisible] = React.useState(true);

  const { t } = useTranslation();

  const toggleOverlay = () => {
    setOverlayVisible(!isOverlayVisible);
  };

  return (
    <div>
      <Button fullWidth onClick={toggleOverlay}>
        {t("header.overlay")}
      </Button>
      {isOverlayVisible && (
        <div className={styles.overlay}>
          <div className={styles.overlayContent}>
            <div>
              <img src={logoSvg} alt="Logo" width="100%" />
            </div>
            <div className={styles.btnBlock}>
              <Button variant="contained" href="/">
                {t("header.tracing")}
              </Button>
              <Button variant="contained" color="error" onClick={toggleOverlay}>
                {t("header.rates")}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Overlay;
