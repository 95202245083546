import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate, Link as LinkRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Box,
  Typography,
  Container,
  Menu,
  MenuItem,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { getAccess } from "../redux/slices/AuthSlice";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="http://centrum-logistics.com">
        Centrum-solutions
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const defaultTheme = createTheme();

const Login = () => {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [errorMsg, setErrorMsg] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const open = Boolean(anchorEl);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("selectedLanguage", lng);
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const resp = await dispatch(
      getAccess({ username: username, password: password })
    );
    if (resp.error) {
      if (resp.error.message.includes("429")) {
        setErrorMsg(t("loginPage.attention"));
      }
    } else navigate("/");
  };

  return (
    <>
      <div style={{ marginTop: "20px", marginLeft: "20px" }}>
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          onClick={handleClick}
          style={{ minWidth: "0", padding: "6px 12px" }}
        >
          {t("language")}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => changeLanguage("en")}
            style={{ padding: "6px 12px", fontSize: "14px" }}
          >
            EN
          </MenuItem>
          <MenuItem
            onClick={() => changeLanguage("ru")}
            style={{ padding: "6px 12px", fontSize: "14px" }}
          >
            RU
          </MenuItem>
        </Menu>
      </div>
      <ThemeProvider theme={defaultTheme}>
        {errorMsg && (
          <div
            style={{
              marginTop: "20px",
              textAlign: "center",
              color: "red",
            }}
          >
            <h3>{errorMsg}</h3>
          </div>
        )}
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {t("loginPage.title")}
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                type="text"
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
                label={t("loginPage.username")}
                autoComplete="off"
                margin="normal"
                required
                fullWidth
              />
              <TextField
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                label={t("loginPage.password")}
                autoComplete="off"
                margin="normal"
                required
                fullWidth
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <FormControlLabel
                  onClick={onShowPassword}
                  control={<Checkbox value="remember" color="primary" />}
                  label={t("loginPage.show_password")}
                />
                <LinkRouter to="http://centrum-logistics.com">
                  {t("loginPage.back_to_site")}
                </LinkRouter>
              </div>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="success"
                sx={{ mt: 3, mb: 2 }}
              >
                {t("loginPage.sign_in")}
              </Button>
              {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </ThemeProvider>
    </>
  );
};

export default Login;
